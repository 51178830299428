import { render, staticRenderFns } from "./articleEdit.vue?vue&type=template&id=5cd8c9c3&scoped=true"
import script from "./articleEdit.vue?vue&type=script&lang=js"
export * from "./articleEdit.vue?vue&type=script&lang=js"
import style0 from "./articleEdit.vue?vue&type=style&index=0&id=5cd8c9c3&prod&lang=scss"
import style1 from "./articleEdit.vue?vue&type=style&index=1&id=5cd8c9c3&prod&scoped=true&lang=scss"
import style2 from "./articleEdit.vue?vue&type=style&index=2&id=5cd8c9c3&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd8c9c3",
  null
  
)

export default component.exports