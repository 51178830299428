<template>
  <transition name="modal">
    <div class="modal-mask" v-if="show">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="w-full flex flex-row-reverse">
            <img
              class="close-icon"
              src="../assets/close.png"
              @click="onClose"
            />
          </div>
          <div class="modal-title"><slot name="title"></slot></div>
          <slot name="content"></slot>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  display: flex;
  align-items: center;
}

.modal-container {
  width: 400px;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  .close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .modal-title {
    text-align: center;
    width: 100%;
    color: #1a202c;
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 600;
  }
}

/* 进入动画 */
.modal-enter-active {
  animation: modal-in 0.3s ease-out;
}

/* 离开动画 */
.modal-leave-active {
  animation: modal-out 0.3s ease-in;
}

/* 进入结束 */
.modal-enter-to {
  opacity: 1;
}

/* 离开结束 */
.modal-leave-to {
  opacity: 0;
}

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>
