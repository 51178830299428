<template>
  <div class="relative user-container">
    <div
      class="flex items-center gap-2 user-dropdown"
      ref="term"
      @click="show = !show"
    >
      <el-avatar class="avatar">
        <img src="../assets/defauthead.png" />
      </el-avatar>
      <p class="name">{{ user_email || "" }}</p>
      <img src="../assets/arrow.png" class="arrow-icon" />
    </div>
    <el-collapse-transition>
      <div
        class="dropdown-items shadow-lg absolute right-0 divide-y divide-gray-100"
        ref="drop"
        v-show="show"
      >
        <ul
          class="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="avatarButton"
        >
          <!-- <li>
            <a
              @click="gotoMessages"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >Feedback</a
            >
          </li> -->
          <li v-if="$route.path === '/home/my-refffs'">
            <a
              @click="onExport"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >Export</a
            >
          </li>
        </ul>

        <div class="py-1">
          <a
            @click="showLogoutDialog = true"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >Sign out</a
          >
        </div>
      </div>
    </el-collapse-transition>

    <Modal :show="showLogoutDialog" @close="showLogoutDialog = false">
      <div slot="title">Notice</div>
      <div slot="content" class="logout-content">
        Are you sure you want to log out?
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="doLogout">Confirm</div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  clearUser,
  getPublisherEmail,
  getUserEmail,
  getUserType,
  getUserName,
} from "../utils/store";
import Modal from "./Modal.vue";
import "../assets/common/common.css";
import "../assets/common/font.css";
export default {
  name: "Avatar",
  components: {
    Modal,
  },
  mixins: [],
  props: {},
  data() {
    return {
      show: false,
      user_email: "",
      showLogoutDialog: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    document.addEventListener("click", this.clickOut);
    this.user_email = getUserName() || getPublisherEmail() || "";
  },
  methods: {
    gotoMessages() {
      this.$router.push("/home/messages");
    },
    clickOut(e) {
      let drop = this.$refs.drop;
      let term = this.$refs.term;
      if (
        drop &&
        !drop.contains(e.target) &&
        term &&
        !term.contains(e.target) &&
        this.show
      ) {
        this.show = false;
      }
    },
    doLogout() {
      clearUser();
      this.$router.replace("/login");
    },
    onExport() {
      this.$emit("export");
    },
    logout() {
      this.$alert("Are you sure you want to log out？", "Notice", {
        confirmButtonText: "Confirm",
        callback: (action) => {
          if (action == "confirm") {
            clearUser();
            location.reload();
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-container {
  width: fit-content;
}

.user-dropdown {
  border-radius: 48px;
  background: #fafafa;
  padding: 6px;
  width: fit-content;
  cursor: pointer;

  .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #1a203c;
  }

  .arrow-icon {
    width: 14px;
    height: 14px;
    margin: 0 4px;
  }
}

.dropdown-items {
  width: fit-content;
  min-width: 160px;
  border-radius: 16px;
  top: 48px;
  background: white;
  cursor: pointer;
}

.logout-content {
  font-size: 14px;
  color: #a0aec0;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.custom-btn {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  color: white;
  font-family: Poppins;
  background: #ec652b;
  height: 48px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;

  .btn-icon {
    width: 16px;
    height: 16px;
  }

  &.delete-btn {
    // background: #fe5024;
    border: 1px solid #fe5024;
    background-color: white;
  }

  &.back {
    color: #ec652b;
    border: 2px solid #ec652b;
    height: 48px;
    background: white;
  }
}
</style>
