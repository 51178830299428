<template>
  <div class="view-root view-content edit-note-container bg-white">
    <div class="flex justify-between items-center mb-6">
      <div class="custom-btn back" @click="goBack">
        <img class="btn-icon" src="../../assets/back.png" />
        Back
      </div>
      <p class="title">My Article</p>
      <Avatar />
    </div>
    <div class="flex gap-6">
      <div class="edit-content-container">
        <div class="flex items-center justify-end">
          <div class="flex items-center gap-2">
            <el-radio-group
              v-model="editMode"
              v-if="isInEditMode"
              style="margin-right: 10px"
            >
              <el-radio-button label="full">Edit Full-Text</el-radio-button>
              <el-radio-button label="paragraph"
                >Edit Paragraphs</el-radio-button
              >
            </el-radio-group>
            <div class="custom-btn" @click="clickEditBtn" v-if="!isInEditMode">
              <i class="el-icon-edit" style="font-size: 16px" />
              Edit
            </div>
            <div class="custom-btn" v-if="isInEditMode" @click="clickEditBtn">
              <img class="btn-icon" src="../../assets/check.png" />Save
            </div>
            <div class="custom-btn delete-btn" @click="showDeleteNoteTips()">
              <img
                src="../../assets/icon_delete.png"
                style="object-fit: cover; width: 11px; height: 12px"
              />
              <!-- <i class="el-icon-delete btn-icon" style="font-size: 16px" /> -->
            </div>
          </div>
        </div>
        <div class="edit-note">
          <div class="flex items-center justify-between note-info">
            <div>
              {{
                $dayjs(cardNoteInfo.create_time * 1000).format("MMMM D, YYYY")
              }}
            </div>
            <div class="flex items-center gap-2">
              Audience <img class="view-icon" src="../../assets/view.png" />{{
                cardNoteInfo.read_number
              }}
            </div>
          </div>
          <div class="note-input">
            <p
              v-if="!isInEditMode"
              class="note-title"
              v-html="cardNoteInfo.title"
            ></p>
            <el-input
              v-else
              v-model="cardNoteInfo.title"
              class="note-title"
              :disabled="!isInEditMode"
              placeholder="Please input title"
            />
            <div v-if="isInEditMode">
              <quill-editor
                v-if="editMode === 'full'"
                placeholder="Please input content"
                class="editor"
                ref="myTextEditor"
                v-model="cardNoteInfo.content"
                :options="editorOption"
              >
              </quill-editor>
              <div v-else class="paramode">
                <div style="margin-bottom: 10px">
                  <el-button @click="addp" type="primary"
                    >Add Paragraph</el-button
                  >
                </div>
                <div
                  style="display: flex; align-items: start"
                  v-for="(item, index) in cardNoteInfo.paragraph"
                  :key="index"
                >
                  <quill-editor
                    placeholder="Please input content"
                    class="editor"
                    style="width: 100%"
                    v-model="cardNoteInfo.paragraph[index]"
                    :options="editorOption"
                  ></quill-editor>
                  <el-button
                    type="primary"
                    @click="cardNoteInfo.paragraph.splice(index, 1)"
                    style="margin-left: 10px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
              </div>
            </div>
            <p v-else class="input_content" v-html="cardNoteInfo.content"></p>

            <h3 class="summary" v-if="id">Summary</h3>

            <el-input
              v-if="isInEditMode && id"
              :rows="5"
              type="textarea"
              v-model="cardNoteInfo.summary"
              autosize
              placeholder="Please input summary"
            />
            <p
              style="margin-top: 16px"
              v-else-if="id"
              class="input_content"
              v-html="changeToHtml(cardNoteInfo.summary)"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="showDeleteModal" @close="showDeleteModal = false">
      <div slot="title">Delete This Article</div>
      <div slot="content" class="delete-content">
        You are about to delete this article
      </div>
      <div slot="footer">
        <div class="w-full custom-btn" @click="onDelete">Delete</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getArticle, updateArticle, removeArticle } from "../../api/reff";
import { Loading } from "element-ui";
import "../../assets/common/common.css";
import "../../assets/common/font.css";
import { getUserId } from "../../utils/store";
import Avatar from "../../components/Avatar.vue";
import Modal from "../../components/Modal.vue";

export default {
  name: "index",
  components: {
    Avatar,
    Modal,
  },
  data() {
    return {
      editMode: "full",
      loadingData: false,
      isInEditMode: false,
      id: this.$route.query.id,
      noteInfo: {},
      showTime: "",
      showAddTagDialog: false,
      tempTag: "",
      loadingInstance: undefined,
      remindTimes: [],
      cardNoteInfo: {
        title: "",
        content: "",
        create_time: Date.now() / 1000,
        read_number: 0,
        paragraph: [],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测,
      },
      showDeleteModal: false,
    };
  },
  mounted() {
    if (this.id) {
      this.fetchData();
    } else {
      this.isInEditMode = true;
    }
  },
  methods: {
    changeToHtml(data) {
      if (data) {
        if (data.indexOf("```") !== -1) {
          const count = data.split("```").length - 1;
          if (count % 2 === 0) {
            return window.marked.parse(data);
          } else {
            return window.marked.parse(data + "\n\n```");
          }
        } else {
          return window.marked.parse(data);
        }
      } else {
        return "";
      }
    },
    showDeleteNoteTips() {
      this.showDeleteModal = true;
    },

    onDelete() {
      removeArticle({
        article_id: this.id,
      }).then((res) => {
        this.$message.success("Deleted");
        this.$router.go(-1);
      });
    },

    formatBtnStyle() {
      let style = {};
      if (this.isInEditMode) {
        style.backgroundColor = "#FFC569";
        style.color = "white";
        style.borderColor = "#FFC569";
      } else {
        style.backgroundColor = "white";
        style.color = "#333333";
        style.borderColor = "#DCDFE6";
      }
      return style;
    },

    clickEditBtn() {
      if (this.isInEditMode == false) {
        this.isInEditMode = !this.isInEditMode;
      } else {
        this.saveNote();
      }
    },
    async saveNote() {
      if (!this.cardNoteInfo.title) {
        this.$message.error("Please input title");
        return;
      }
      if (!this.cardNoteInfo.content && this.editMode === "full") {
        this.$message.error("Please input content");
        return;
      }
      this.$confirm(
        this.editMode === "full"
          ? "Do you want to split paragraphs automatically by AI?"
          : "Do you to automatically assemble the full text?",
        "Warning",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "No Need",
          showCancelButton: this.$route.query.id ? true : false,
          type: "warning",
        }
      )
        .then(() => {
          this.loadingInstance = Loading.service({
            fullscreen: true,
            text: this.id
              ? "Data submission in progress, please wait"
              : "Generating article summary",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.8)",
          });
          let params = {
            id: this.id,
            user_id: getUserId(),
            title: this.cardNoteInfo.title,
            content: this.cardNoteInfo.content,
            mode: this.editMode,
            paragraph: this.cardNoteInfo.paragraph || [],
            aiParagragh: this.editMode === "full" ? true : false,
          };
          if (this.editMode === "paragraph") {
            params.content = this.cardNoteInfo.paragraph.join("<br><br>");
          }
          if (this.id) {
            params.summary = this.cardNoteInfo.summary;
          }
          updateArticle(params).then((res) => {
            this.loadingInstance.close();
            this.$message.success("Save success~");
            if (this.$route.query.id) {
              this.isInEditMode = false;
              this.fetchData();
            } else {
              this.$router.back(-1);
            }
          });
        })
        .catch((action) => {
          console.log(action);
          if (action === "cancel") {
            this.loadingInstance = Loading.service({
              fullscreen: true,
              text: this.id
                ? "Data submission in progress, please wait"
                : "Generating article summary",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.8)",
            });
            let params = {
              id: this.id,
              user_id: getUserId(),
              title: this.cardNoteInfo.title,
              content: this.cardNoteInfo.content,
              mode: this.editMode,
              paragraph: this.cardNoteInfo.paragraph || [],
              aiParagragh: false,
            };
            if (this.id) {
              params.summary = this.cardNoteInfo.summary;
            }
            updateArticle(params).then((res) => {
              this.loadingInstance.close();
              this.$message.success("Save success~");
              if (this.$route.query.id) {
                this.isInEditMode = false;
                this.fetchData();
              } else {
                this.$router.back(-1);
              }
            });
          }
        });
    },

    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getArticle({
        article_id: this.id,
      })
        .then((res) => {
          let result = res.data.data;
          this.cardNoteInfo = result;
          this.cardNoteInfo.paragraph = this.cardNoteInfo.paragraph || [];
          this.formatCreateTime();
        })
        .finally(() => {
          loading.close();
        });
    },
    formatCreateTime() {
      let create_time = this.cardNoteInfo.create_time;
      var date = new Date(create_time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      this.showTime = this.formatMonthToEng(month) + " " + day + ", " + year;
    },
    goBack() {
      this.$router.go(-1);
    },
    addp() {
      this.cardNoteInfo.paragraph.push("");
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
  },
};
</script>

<style lang="scss">
.paramode {
  .quill-editor {
    min-height: 260px;
    .ql-container {
      min-height: 200px;
    }
    .ql-blank {
      min-height: 200px;
    }
    .ql-editor {
      min-height: 200px;
    }
  }
}
.quill-editor {
  min-height: calc(100vh - 385px);
  .ql-container {
    min-height: calc(100vh - 465px);
  }
  .ql-blank {
    min-height: calc(100vh - 465px);
  }
  .ql-editor {
    min-height: calc(100vh - 465px);
  }
}
</style>

<style scoped lang="scss">
.edit-note-container {
  padding: 32px;
  overflow-y: scroll;
  position: relative;
  .title {
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
  }
  .summary {
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
    margin: 24px 0 12px;
  }
  .timeline-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    border: 1px solid #edf2f7;
    border-radius: 12px;
    padding: 24px;
    .timeline-title {
      font-size: 22px;
      font-weight: 600;
      color: #1a202c;
    }
    .timeline-item-wrapper {
      .timeline-item {
        font-size: 15px;
        margin-bottom: 12px;
        &.done {
          color: #a0aec0;
          .detail {
            .dot {
              background: #a0aec0;
            }
          }
        }
        &.during {
          color: #ec652b;
          .detail {
            .dot {
              background: #ec652b;
            }
          }
        }
        .detail {
          font-weight: 600;
          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-right: 12px;
            background: #1a202c;
          }
        }
      }
    }
  }
  .edit-content-container {
    flex: 1;
    .edit-note {
      border-radius: 16px;
      border: 1px solid #edf2f7;
      padding: 24px;
      margin-top: 24px;
      min-height: calc(100vh - 202px);
    }
    .note-info {
      color: #005a64;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      .view-icon {
        width: 14px;
        height: 14px;
      }
    }
    .input_content {
      font-size: 14px;
      line-height: 150%;
      font-style: normal;
      background: transparent;
      font-family: Manrope;
      color: #718096;

      ::v-deep {
        ul,
        ol {
          list-style: inside;
        }
      }
    }
    .note-input {
      .note-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        width: 100%;
      }
      .note-content {
        width: 100%;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        font-family: Manrope;
        color: #718096;
      }
      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        font-weight: 600 !important;
      }
      &::v-deep .el-textarea__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        background: transparent;
        font-family: Manrope;
        color: #718096;
        cursor: auto !important;
      }
    }
  }
  .custom-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    color: white;
    font-family: Poppins;
    background: #ec652b;
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    .btn-icon {
      width: 16px;
      height: 16px;
    }
    &.delete-btn {
      // background: #fe5024;
      border: 1px solid #fe5024;
      background-color: white;
    }
    &.back {
      color: #ec652b;
      border: 2px solid #ec652b;
      height: 48px;
      background: white;
    }
  }
}
.delete-content {
  font-size: 14px;
  color: #a0aec0;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .tag {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 12px;
    .tag-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
<style>
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #ec652b;
  color: white;
  border-color: #ec652b;
  box-shadow: none;
}
.el-radio-button__inner:hover {
  color: #ec652b;
}
.el-button--primary {
  background-color: #ec652b !important;
  border-color: #ec652b !important;
}
</style>
